///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	let activeAlert = null;

	const cssClasses = {
		ALERT_ACTIVE: 'alert-active',
		ALERT_EL: 'alert-el',
		ALERT_HDR: 'alert-header',
		ALERT_INFO: 'alert-info',
		BTN: 'alert-btn',
		BTN_CONFIRM: 'alert-btn-confirm',
		BTN_CONTAINER: 'alert-btn-container',
		BTN_DISMISS: 'alert-btn-dismiss',
		CONTAINER: 'alert-container'
	};

	const keys = {
		ESCAPE: 27
	};

	class Alert {
		constructor() {
			if ( activeAlert ) {
				console.log( 'An alert already exists.' );
				return activeAlert;
			}

			this.alertContainer = this.createAlertElements();

			this.onConfirm = function() { };
			this.onDismiss = function() { };

			this.promptAlert = this.promptAlert.bind( this );
			this.updateButtons = this.updateButtons.bind( this );
			this.showAlert = this.showAlert.bind( this );
			this.handleKeyDown = this.handleKeyDown.bind( this );
			this.dismissAlert = this.dismissAlert.bind( this );

			this.addEventListeners();

			activeAlert = this;
		}

		createAlertElements() {
			let el = doc.createElement( 'div' );
			el.classList.add( cssClasses.CONTAINER );
			doc.body.appendChild( el );

			this.alertEl = doc.createElement( 'div' );
			this.alertEl.classList.add( cssClasses.ALERT_EL );

			this.alertHeader = doc.createElement( 'h3' );
			this.alertHeader.classList.add( cssClasses.ALERT_HDR );

			this.alertInfo = doc.createElement( 'div' );
			this.alertInfo.classList.add( cssClasses.ALERT_INFO );

			let btnContainer = doc.createElement( 'div' );
			btnContainer.classList.add( cssClasses.BTN_CONTAINER );

			this.btnDismiss = doc.createElement( 'button' );
			this.btnDismiss.classList.add( cssClasses.BTN );
			this.btnDismiss.classList.add( cssClasses.BTN_CONFIRM );
			this.btnDismiss.classList.add( 'btn-ripple' );
			this.btnDismiss.textContent = 'Dismiss';

			this.btnConfirm = doc.createElement( 'button' );
			this.btnConfirm.classList.add( cssClasses.BTN );
			this.btnConfirm.classList.add( cssClasses.BTN_CONFIRM );
			this.btnConfirm.classList.add( 'btn-ripple' );
			this.btnConfirm.textContent = 'Confirm';

			btnContainer.appendChild( this.btnDismiss );
			btnContainer.appendChild( this.btnConfirm );

			el.appendChild( this.alertEl );
			this.alertEl.appendChild( this.alertHeader );
			this.alertEl.appendChild( this.alertInfo );
			this.alertEl.appendChild( btnContainer );

			return el;
		}

		addEventListeners() {
			this.btnConfirm.addEventListener( 'click', this.onConfirm, false );
			this.btnDismiss.addEventListener( 'click', this.onDismiss, false );
		}

		promptAlert( hdr, content, btnConfirmText, btnDismissText, fnConfirm, fnDismiss ) {
			this.alertHeader.textContent = hdr;
			this.alertInfo.innerHTML = content;
			this.updateButtons( btnConfirmText, btnDismissText, fnConfirm, fnDismiss );
			this.showAlert();

			doc.body.addEventListener( 'keydown', this.handleKeyDown, false );
		}

		updateButtons( btnConfirmText, btnDismissText, fnConfirm, fnDismiss ) {
			this.btnConfirm.textContent = btnConfirmText;
			this.btnDismiss.textContent = btnDismissText;

			this.btnDismiss.MaterialButton = new MaterialButton( this.btnDismiss );
			this.btnConfirm.MaterialButton = new MaterialButton( this.btnConfirm );

			this.btnConfirm.removeEventListener( 'click', this.onConfirm, false );
			this.btnDismiss.removeEventListener( 'click', this.onDismiss, false );

			this.onConfirm = fnConfirm;
			this.onDismiss = fnDismiss;

			this.btnConfirm.addEventListener( 'click', this.onConfirm, false );
			this.btnDismiss.addEventListener( 'click', this.onDismiss, false );
		}

		showAlert() {
			doc.body.classList.add( cssClasses.ALERT_ACTIVE );
		}

		handleKeyDown( e ) {
			if ( e.keyCode === keys.ESCAPE ) {
				this.onDismiss();
			}
		}

		dismissAlert() {
			doc.body.classList.remove( cssClasses.ALERT_ACTIVE );
			doc.body.removeEventListener( 'keydown', this.handleKeyPress, false );
		}
	}

	app.Alert = Alert;

}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	const cssClasses = {
		ANIMATABLE: 'animatable',
		NAV_IN: 'side-nav-in'
	};

	class SideNav {
		constructor() {
			this.btnShow = app.$( '.hdr-nav-trigger' );
			this.sideNav = app.$( '.side-nav' );
			this.sideNavContainer = app.$( '.side-nav-container' );
			this.sideNavClose = app.$( '.side-nav-close' );

			this.showSideNav = this.showSideNav.bind( this );
			this.hideSideNav = this.hideSideNav.bind( this );
			this.onTouchStart = this.onTouchStart.bind( this );
			this.onTouchMove = this.onTouchMove.bind( this );
			this.onTouchEnd = this.onTouchEnd.bind( this );
			this.onTransitionEnd = this.onTransitionEnd.bind( this );
			this.update = this.update.bind( this );

			this.startX = 0;
			this.currentX = 0;
			this.touchingSideNav = false;

			this.addEventListeners();
		}

		addEventListeners() {
			this.btnShow.addEventListener( 'click', this.showSideNav );
			this.sideNav.addEventListener( 'click', this.hideSideNav );
			this.sideNavContainer.addEventListener( 'click', this.blockClicks );
			this.sideNavClose.addEventListener( 'click', this.hideSideNav );
			this.sideNav.addEventListener( 'touchstart', this.onTouchStart );

			this.sideNav.addEventListener( 'touchmove', this.onTouchMove );
			this.sideNav.addEventListener( 'touchend', this.onTouchEnd );
		}

		showSideNav( e ) {
			this.sideNav.classList.add( cssClasses.ANIMATABLE );
			doc.body.classList.add( cssClasses.NAV_IN );
			this.sideNav.addEventListener( 'transitionend', this.onTransitionEnd );
			e.preventDefault();
		}

		hideSideNav( e ) {
			this.sideNav.classList.add( 'animatable' );
			doc.body.classList.remove( cssClasses.NAV_IN );
			this.sideNav.addEventListener( 'transitionend', this.onTransitionEnd );

			if ( e ) {
				e.preventDefault();
			}
		}

		blockClicks( e ) {
			e.stopPropagation();
		}

		onTouchStart( e ) {
			if ( !doc.body.classList.contains( cssClasses.NAV_IN ) ) {
				return;
			}

			this.startX = e.touches[0].pageX;
			this.currentX = this.startX;

			this.touchingSideNav = true;
			requestAnimationFrame( this.update );
		}

		onTouchMove( e ) {
			if ( !this.touchingSideNav ) {
				return;
			}

			this.currentX = e.touches[0].pageX;
			const translateX = Math.min( 0, this.currentX - this.startX );

			if ( translateX < 0 ) {
				e.preventDefault();
			}
		}

		onTouchEnd( e ) {
			if ( !this.touchingSideNav ) {
				return;
			}

			this.touchingSideNav = false;

			const translateX = Math.min( 0, this.currentX - this.startX );
			this.sideNavContainer.style.transform = '';

			if ( translateX < 0 ) {
				this.hideSideNav();
			}
		}

		update() {
			if ( !this.touchingSideNav ) {
				return;
			}

			requestAnimationFrame( this.update );

			const translateX = Math.min( 0, this.currentX - this.startX );
			this.sideNavContainer.style.transform = 'translateX(' + translateX + 'px)';
		}

		onTransitionEnd( e ) {
			this.sideNav.classList.remove( cssClasses.ANIMATABLE );
			this.sideNav.removeEventListener( 'transitionend', this.onTransitionEnd );
		}
	}

	if ( app.$( '.side-nav' ) ) {
		new SideNav();
	}

}( document ) );
(function( doc ) {
    'use strict';

    const settings = {
        DELAY: 5000,
        TRANS_TIME: 750
    };

    class Slide {
        constructor( el ) {
            this.container = el;
            this.list = el.querySelector( 'slide-list' );
            this.slides = el.querySelectorAll( '.slide' );

            this.length = this.slides.length;
            this.index = 0;

            this.rotate = this.rotate.bind( this );

            setTimeout( this.rotate, settings.DELAY );
        }

        rotate() {
            let curIndex = this.index;
            this.index++;

            if ( this.index === this.length ) {
                this.index = 0;
            }

            this.slides[curIndex].classList.remove( 'slide-active' );
            this.slides[this.index].classList.add( 'slide-active' );

            setTimeout( this.rotate, settings.TRANS_TIME + settings.DELAY );
        }
    }

    app.$.forEach( '.slide-container', el => {
        new Slide( el );
    } );

}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	const cssClasses = {
		ACTIVE: 'active',
		CONTAINER: 'toast-container',
		CONTENT: 'toast-content',
		DISMISSED: 'toast-dismissed',
		TOAST: 'toast'
	};

	class Toast {
		static create( msg, options ) {
			
			// make sure we have a toast container
			let toastContainer = doc.querySelector( '.' + cssClasses.CONTAINER );

			if ( !toastContainer ) {
				toastContainer = doc.createElement( 'div' );
				toastContainer.classList.add( cssClasses.CONTAINER );
				doc.body.appendChild( toastContainer );
			}

			// potential toast options
			options = options || {};

			let tag = options.tag || ( Date.now().toString() );

			// avoid duplicate toasts
			app.$.forEach( `.toast[data-tag="${tag}"]`, t => {
				t.parentNode.removeChild( t );
			} );

			// remove any existing toasts
			let existing = doc.querySelector( '.' + cssClasses.TOAST );

			if ( existing ) {
				existing.parentNode.removeChild( existing );
			}

			// make a toast
			let toast = doc.createElement( 'div' );
			let toastContent = doc.createElement( 'div' );
			toast.classList.add( cssClasses.TOAST );
			toastContent.classList.add( cssClasses.CONTENT );
			toastContent.textContent = msg;
			toast.appendChild( toastContent );
			toast.dataset.tag = tag;
			toastContainer.appendChild( toast );

			// fade out after 3 seconds
			let timeout = options.timeout || 3000;
			setTimeout( () => {
				toast.classList.add( cssClasses.DISMISSED );
			}, timeout );

			// after the toast fades out remove it from the DOM
			toast.addEventListener( 'transitionend', e => {
				e.target.parentNode.removeChild( e.target );
			} );
		}
	} 

	app.Toast = Toast;
}( document ) );
window.requestAnimationFrame = (function() {
    return  window.requestAnimationFrame       ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame    ||
            function( callback ){
                window.setTimeout(callback, 1000 / 60);
            };
}());
///<reference path="../main.js">
/**
 * Copyright 2015 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	var MaterialButton = function MaterialButton( element ) {
		this.element_ = element;
		this.init();
	};

	window.MaterialButton = MaterialButton;

	// Definte css classes
	MaterialButton.prototype.cssClasses_ = {
		RIPPLE_BUTTON: 'btn-ripple',
		RIPPLE_CONTAINER: 'btn-ripple-container',
		RIPPLE: 'btn-ripple-element',
		RIPPLE_CENTER: 'btn-ripple-center',
		RIPPLE_IS_ANIMATING: 'is-animating',
		RIPPLE_IS_VISIBLE: 'is-visible'
	};

	MaterialButton.prototype.RippleConstant = {
		INITIAL_SCALE: 'scale(0.0001, 0.0001)',
		INITIAL_SIZE: '1px',
		INITIAL_OPACITY: '0.4',
		FINAL_OPACITY: '0',
		FINAL_SCALE: ''
	};

	// initialize the element
	MaterialButton.prototype.init = function() {
		if ( this.element_ ) {
			
			// if this button needs the ripple effect
			//   add the necessary ripple elements and events
			if ( this.element_.classList.contains( this.cssClasses_.RIPPLE_BUTTON ) ) {
				this.initRipple();
			}
		}
	};

	MaterialButton.prototype.initRipple = function() {
		var recentering;

		// first add the elements
		this.addRippleElements();

		// set defaults and add event handlers
		recentering = this.element_.classList.contains( this.cssClasses_.RIPPLE_CENTER );
		this.frameCount_ = 0;
		this.rippleSize_ = 0;
		this.x_ = 0;
		this.y_ = 0;

		// Touch start produces a compat mouse down event, which would cause a
		// second ripple. To avoid that, we use this property to ignore the first
		// mouse down after a touch start.
		this.ignoringMouseDown_ = false;

		this.boundDownHandler = this.downHandler_.bind( this );
		this.element_.addEventListener( 'mousedown', this.boundDownHandler, false );
		this.element_.addEventListener( 'touchstart', this.boundDownHandler, false );

		this.boundUpHandler = this.upHandler_.bind( this );
		this.element_.addEventListener( 'mouseup', this.boundUpHandler, false );
		this.element_.addEventListener( 'mouseleave', this.boundUpHandler, false );
		this.element_.addEventListener( 'touchend', this.boundUpHandler, false );
		this.element_.addEventListener( 'blur', this.boundUpHandler, false );

		// helpers
		this.getFrameCount = function() {
			return this.frameCount_;
		};

		this.setFrameCount = function( fC ) {
			this.frameCount_ = fC;
		};

		this.getRippleElement = function() {
			return this.rippleElement_;
		};

		this.setRippleXY = function( newX, newY ) {
			this.x_ = newX;
			this.y_ = newY;
		};

		// styles
		this.setRippleStyles = function( start ) {
			if ( this.rippleElement_ !== null ) {
				var transformString, scale, size,
					offset = 'translate(' + this.x_ + 'px, ' + this.y_ + 'px)';

				if ( start ) {
					scale = this.RippleConstant.INITIAL_SCALE;
					size = this.RippleConstant.INITIAL_SIZE;
				}
				else {
					scale = this.RippleConstant.FINAL_SCALE;
					size = this.rippleSize_ + 'px';

					if ( recentering ) {
						offset = 'translate(' + this.boundWidth / 2 + 'px, ' + this.boundHeight / 2 + 'px)';
					}
				}

				transformString = 'translate(-50%, -50%) ' + offset + scale;

				this.rippleElement_.style.webkitTransform = transformString;
				this.rippleElement_.style.msTransform = transformString;
				this.rippleElement_.style.transform = transformString;

				if ( start ) {
					this.rippleElement_.classList.remove( this.cssClasses_.RIPPLE_IS_ANIMATING );
				}
				else {
					this.rippleElement_.classList.add( this.cssClasses_.RIPPLE_IS_ANIMATING );
				}
			}
		};

		// RAF
		this.animFrameHandler = function() {
			if ( this.frameCount_-- > 0 ) {
				requestAnimationFrame( this.animFrameHandler.bind( this ) );
			}
			else {
				this.setRippleStyles( false );
			}
		};
	};

	MaterialButton.prototype.addRippleElements = function() {
		var container = doc.createElement( 'span' );
		container.classList.add( this.cssClasses_.RIPPLE_CONTAINER );

		this.rippleElement_ = doc.createElement( 'span' );
		this.rippleElement_.classList.add( this.cssClasses_.RIPPLE );

		container.appendChild( this.rippleElement_ );

		this.boundRippleBlurHandler = this.blurHandler_.bind( this );
		this.rippleElement_.addEventListener( 'mouseup', this.boundRippleBlurHandler );
		this.element_.appendChild( container );
	};

	// blur event handler
	MaterialButton.prototype.blurHandler_ = function( e ) {
		if ( e ) {
			this.element_.blur();
		}
	};

	// disable the button
	MaterialButton.prototype.disable = function() {
		this.element_.disabled = true;
	};

	// button downHandler
	MaterialButton.prototype.downHandler_ = function( e ) {
		var bound, x, y, clientX, clientY;

		if ( !this.rippleElement_.style.width && !this.rippleElement_.style.height ) {
			var rect = this.element_.getBoundingClientRect();
			this.boundHeight = rect.height;
			this.boundWidth = rect.width;
			this.rippleSize_ = Math.sqrt( rect.width * rect.width + rect.height * rect.height ) * 2 + 2;
			this.rippleElement_.style.width = this.rippleSize_ + 'px';
			this.rippleElement_.style.height = this.rippleSize_ + 'px';
		}

		this.rippleElement_.classList.add( this.cssClasses_.RIPPLE_IS_VISIBLE );

		if ( e.type === 'mousedown' && this.ignoringMouseDown_ ) {
			this.ignoringMouseDown_ = false;
		}
		else {
			if ( e.type === 'touchstart' ) {
				this.ignoringMouseDown_ = true;
			}

			var frameCount = this.getFrameCount();
			if ( frameCount > 0 ) {
				return;
			}

			this.setFrameCount( 1 );

			bound = e.currentTarget.getBoundingClientRect();

			// Check if we are handling a keyboard click.
			if ( e.clientX === 0 && e.clientY === 0 ) {
				x = Math.round( bound.width / 2 );
				y = Math.round( bound.height / 2 );
			} else {
				clientX = e.clientX ? e.clientX : e.touches[0].clientX;
				clientY = e.clientY ? e.clientY : e.touches[0].clientY;
				x = Math.round( clientX - bound.left );
				y = Math.round( clientY - bound.top );
			}

			this.setRippleXY( x, y );
			this.setRippleStyles( true );

			window.requestAnimationFrame( this.animFrameHandler.bind( this ) );
		}
	};

	// button upHandler
	MaterialButton.prototype.upHandler_ = function( e ) {
		// Don't fire for the artificial "mouseup" generated by a double-click.
		if ( e && e.detail !== 2 ) {
			this.rippleElement_.classList.remove( this.cssClasses_.RIPPLE_IS_VISIBLE );
		}

		// Allow a repaint to occur before removing this class, so the animation
		// shows for tap events, which seem to trigger a mouseup too soon after mousedown.
		window.setTimeout( function() {
			this.rippleElement_.classList.remove( this.cssClasses_.RIPPLE_IS_VISIBLE );
		}.bind( this ), 0 );
	};

	// enable the button
	MaterialButton.prototype.enable = function() {
		this.element_.disabled = false;
	};

	app.$.forEach( '.btn-ripple', function( btn ) {
		btn.MaterialButton = new MaterialButton( btn );
	} );
}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	const ckEditorSettings = {
		allowedContent: true,
		height: 350,
		toolbar: 'Simple'
	};

	const formCssClasses = {
		chosenSelect: 'chosen-select',
		ckeditor: 'use-ckeditor',
		errorLabel: 'error-label',
		hidden: 'hidden',
		inputField: 'input-field',
		invalid: 'invalid',
		mediumEditor: 'use-medium-editor',
		required: 'req'
	};

	class Form {
		// When creating a new Form, we can pass in a selected element (i.e. doc.querySelect( '#form' ))
		// or we can pass in a selector (i.e. '#form' )
		constructor( el ) {
			if ( typeof el === 'string' ) {
				el = app.$( el );
			}
			
			this.container = el;
			this.fields = el.querySelectorAll( 'input:not([type="file"]),textarea,.text-editor,select' );
			this.reqFields = el.querySelectorAll( '.' + formCssClasses.required );
			this.inputFields = el.querySelectorAll( '.' + formCssClasses.inputField );

			this.initFormElements = this.initFormElements.bind( this );
			this.initEditors = this.initEditors.bind( this );
			this.checkActiveInputs = this.checkActiveInputs.bind( this );
			this.validateFields = this.validateFields.bind( this );
			this.collectData = this.collectData.bind( this );
			this.setFieldValues = this.setFieldValues.bind( this );
			this.clearForm = this.clearForm.bind( this );

			this.initFormElements();
			this.initEditors();
		}

		// This function will loop through all input field elements to check for
		// inputs and select elements to create our custom TextBox or Select objects
		initFormElements() {
			app.$.forEach( this.inputFields, container => {
				let select = container.querySelector( 'select' );
				let input = container.querySelector( 'input' );
				
				if ( select && !select.Select ) {
					select.Select = new app.Select( select );
				}

				if ( !input ) {
					input = container.querySelector( 'textarea' );
				}

				if ( !input ) {
					input = container.querySelector( '.' + formCssClasses.mediumEditor );
				}

				if ( input ) {
					let tag = input.tagName.toLowerCase();
					let type = input.type.toLowerCase();

					if ( !input.TextBox && type !== 'checkbox' && type !== 'radio' ) {
						input.TextBox = new app.TextBox( input );
					}
				}
			} );
		}

		// Helper functions to initialize textareas with MediumEditor or CKEDITOR
		// depending on specified class
		initEditors() {
			app.$.forEach( this.fields, field => {
				if ( field.classList.contains( formCssClasses.mediumEditor ) ) {
					if ( !window.MediumEditor ) {
						console.warn( 'MediumEditor source not found. Unable to use MediumEditor.' );
					}
					else {
						new MediumEditor( field, {
							placeholder: {
								text: ''
							}
						} );
					}
				}
				else if ( field.classList.contains( formCssClasses.ckeditor ) ) {
					if ( !window.CKEDITOR ) {
						console.warn( 'CKEDITOR source not found. Unable to use CKEDITOR' );
					}
					else {
						CKEDITOR.replace( field.id, ckEditorSettings );

						if ( field.classList.contains( 'ckfinder' ) ) {
							CKFinder.setupCKEditor( CKEDITOR.instances[field.id], '/ckfinder/' );
						}
					}
				}
			} );
		}

		// Check if inputs/selects have values to set or remove active class
		// on the sibling label
		checkActiveInputs() {
			app.$.forEach( this.inputFields, container => {
				let lbl = container.querySelector( 'label' );
				let select = container.querySelector( 'select' );
				let input = container.querySelector( 'input' );

				if ( lbl ) {
					lbl.classList.remove( 'active' );
				}

				if ( select && select.Select ) {
					select.Select.checkForValue();
				}

				if ( !input ) {
					input = container.querySelector( 'textarea' );
				}

				if ( !input ) {
					input = container.querySelector( '.' + formCssClasses.mediumEditor );
				}

				if ( input && input.TextBox ) {
					input.TextBox.checkForValue();
				}
			} );
		}

		// Helper function to check if required fields have valid data
		validateFields() {
			let isValid = true;

			app.$.forEach( this.reqFields, field => {
				let val = '';
				let tag = field.tagName.toLowerCase();

				if ( field.classList.contains( formCssClasses.mediumEditor ) ) {
					val = field.innerHTML;
				}
				else if ( field.classList.contains( formCssClasses.ckeditor ) ) {
					val = CKEDITOR.instances[field.id].getData().trim();
				}
				else if ( tag === 'select' ) {
					if ( field.Select ) {
						val = field.Select.getValue();
					}
					else if ( !field.classList.contains( formCssClasses.chosenSelect ) ) {
						val = field.options[field.selectedIndex].value;
					}
				}
				else {
					val = field.value.trim();
				}

				if ( val === '' || val === '-1' || val === -1 ) {
					field.classList.add( formCssClasses.invalid );
					isValid = false;
				}
				else {
					field.value = val;
					field.classList.remove( formCssClasses.invalid );
				}
			} );

			return isValid;
		}

		// Helper function to collect data from form fields and return as
		// JSON key/value pair object. Uses the element's name attribute as the key.
		collectData() {
			let params = {};

			app.$.forEach( this.fields, field => {
				let key = field.getAttribute( 'name' );
				let val = '';
				let tag = field.tagName.toLowerCase();
				let type = field.type ? field.type.toLowerCase() : '';

				if ( type === 'checkbox' ) {
					val = field.checked;
				}
				else if ( field.classList.contains( formCssClasses.mediumEditor ) ) {
					val = field.innerHTML;
				}
				else if ( field.classList.contains( formCssClasses.ckeditor ) ) {
					val = CKEDITOR.instances[field.id].getData().trim();
				}
				else if ( tag === 'select' ) {
					if ( field.Select ) {
						val = field.Select.getValue();
					}
					else if ( !field.classList.contains( formCssClasses.chosenSelect ) ) {
						val = field.options[field.selectedIndex].value;
					}
				}
				else {
					val = field.value.trim();

					if ( field.classList.contains( 'integer' ) ) {
						val = parseInt( val, 10 );
					}
					else if ( field.classList.contains( 'decimal' ) ) {
						val = parseFloat( val );
					}
				}

				params[key] = val;
			} );

			return params;
		}

		// Helper function to set the values of form fields. Will use the 
		// name attribute of each field element to select the respective value
		// from the obj parameter.
		setFieldValues( obj ) {
			app.$.forEach( this.fields, field => {
				let val = obj[field.getAttribute( 'name' )];
				let type = field.type ? field.type.toLowerCase() : '';
				let tag = field.tagName.toLowerCase();

				if ( !obj ) {
					console.warn( 'Property does not exist for key ' + field.getAttribute( 'name' ) );
				}
				else {
					if ( type === 'checkbox' ) {
						field.checked = val;
					}
					else if ( field.classList.contains( formCssClasses.mediumEditor ) ) {
						field.innerHTML = val;
					}
					else if ( field.classList.contains( formCssClasses.ckeditor ) ) {
						val = CKEDITOR.instances[field.id].setData( val );
					}
					else if ( tag === 'select' && field.Select ) {
						field.Select.setValue( val );
					}
					else {
						if ( field.classList.contains( 'input-date' ) ) {
							if ( obj[field.getAttribute( 'name' ) + 'Str'] ) {
								val = obj[field.getAttribute( 'name' ) + 'Str'];
							}
							else {
								val = moment( val ).format( 'MM/DD/YYYY' );
							}
						}

						field.value = val;
					}
				}
			} );

			this.checkActiveInputs();
		}

		// Helper function to clear out the values of a form. Will additionally
		// remove all HTML from containers with class .row-preview and hide
		// all elements with class .btn-item-upload-delete. It will then
		// run checkActiveInputs to reset the labels.
		clearForm() {
			app.$.forEach( this.fields, field => {
				let lbl = field.querySelector( '.' + formCssClasses.errorLabel );
				let type = field.type ? field.type.toLowerCase() : '';
				let tag = field.tagName.toLowerCase();

				if ( type === 'checkbox' ) {
					field.checked = false;
				}
				else if ( field.classList.contains( formCssClasses.mediumEditor ) ) {
					field.innerHTML = '';
				}
				else if ( tag === 'textarea' ) {
					CKEDITOR.instances[field.id].setData( '' );
				}
				else if ( tag === 'select' ) {
					if ( field.Select ) {
						field.Select.setValue( '-1' );
					}
					else if ( field.multiple && field.classList.contains( formCssClasses.chosenSelect ) ) {
						app.$.forEach( field.querySelectorAll( 'option' ), function( opt ) {
							opt.selected = false;
						} );

						jQuery( field ).trigger( 'chosen:updated' );
					}
					else {
						field.value = '-1';
					}
				}
				else {
					field.value = '';
				}

				field.classList.remove( formCssClasses.invalid );

				if ( lbl ) {
					field.parentNode.removeChild( lbl );
				}
			} );

			app.$.forEach( '.row-preview', row => {
				row.innerHTML = '';
			}, this.container );

			app.$.forEach( '.btn-item-upload-delete', btn => {
				btn.classList.add( 'hidden' );
			}, this.container );

			this.checkActiveInputs();
		}

		hide() {
			this.container.classList.add( formCssClasses.hidden );
		}

		show() {
			this.checkActiveInputs();
			this.container.classList.remove( formCssClasses.hidden );
		}
	}

	// Expose the Form object to the rest of the project
	app.Form = Form;
}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	const cssClasses = {
		ERROR_LABEL: 'error-label',
		INVALID_REQ_FIELD: 'invalid',
		MD_MENU: 'md-select',
		MENU: 'md-select-menu',
		MENU_OPEN: 'active',
		LIST_ITEM: 'md-select-menu-item',
		LIST_ITEM_HOVER: 'keyover',
		REQUIRED_FIELD: 'req'
	};

	class Select {
		constructor( select ) {
			// if on a touch device, default to native select
			if ( 'ontouchstart' in doc.documentElement && app.$.mq( '(max-width:1024px)' ) ) {
				return;
			}

			// elements
			this.select = select;
			this.select.classList.add( cssClasses.MD_MENU );
			this.container = this.select.parentNode;
			this.label = this.container.querySelector( 'label' );
			this.menu = null;

			// if this is a multiple select
			this.multiple = this.select.multiple;

			this.open = false;
			this.currentIndex = 0;

			this.listItems = [];

			//this.onChange = function() { };

			this.createMenu = this.createMenu.bind( this );
			this.setMenuPosition = this.setMenuPosition.bind( this );
			this.showMenu = this.showMenu.bind( this );
			this.hideMenu = this.hideMenu.bind( this );
			this.changeHandler = this.changeHandler.bind( this );
			this.focusHanlder = this.focusHanlder.bind( this );
			this.blurHandler = this.blurHandler.bind( this );
			this.keyDownHandler = this.keyDownHandler.bind( this );
			this.bodyClickHandler = this.bodyClickHandler.bind( this );
			this.checkIfRequired = this.checkIfRequired.bind( this );

			this.createMenu();
			this.addEventListeners();
		}

		addEventListeners() {
			this.select.addEventListener( 'focus', this.focusHanlder, false );
			this.select.addEventListener( 'blur', this.blurHandler, false );
		}

		// add change event
		setChangeEvent( fn ) {
			this.onChange = fn;
		}

		// create the drop down
		createMenu() {
			let opts = this.select.children;
			let len = opts.length;
			let i;
			let opt;
			let listItem;
			let li;

			this.menu = doc.createElement( 'ul' );
			this.menu.classList.add( cssClasses.MENU );

			// loop through all of the options and create the ListItems
			for ( i = 0; i < len; i++ ) {
				opt = opts[i];

				listItem = new ListItem( opt, i, this );
				this.listItems.push( listItem );
				this.menu.appendChild( listItem.listItem );
			}

			doc.body.appendChild( this.menu );

			this.setMenuPosition();
		}

		// reset the menu
		reloadMenu() {
			let container;

			if ( this.menu ) {
				container = this.menu.parentNode;
				container.removeChild( this.menu );
				this.listItems = [];
				this.createMenu();
			}
		}

		// set the menu position on the page
		setMenuPosition() {
			const rect = this.select.getBoundingClientRect();
			this.menu.style.top = rect.bottom + app.util.getWindowScrollPosition() + 'px';
			this.menu.style.left = rect.left + 'px';
			this.menu.style.width = this.select.offsetWidth + 'px';
		}

		// show the menu
		showMenu() {
			let me = this;

			// always start at the top of the list
			this.menu.scrollTop = 0;

			this.currentIndex = -1;
			this.menu.classList.add( cssClasses.MENU_OPEN );
			this.open = true;

			setTimeout( function() {
				doc.body.addEventListener( 'keydown', me.keyDownHandler, false );
				doc.body.addEventListener( 'click', me.bodyClickHandler, false );

				me.select.blur();
			}, 100 );
						
			// disable window from scrolling
			app.util.disableWindowScroll();
		}

		// hide the menu
		hideMenu() {
			let item;

			this.menu.classList.remove( cssClasses.MENU_OPEN );
			this.open = false;

			// make sure no listItems ahve the keyover class
			item = this.menu.querySelector( '.' + cssClasses.LIST_ITEM_HOVER );

			if ( item ) {
				item.classList.remove( cssClasses.LIST_ITEM_HOVER );
			}

			// remove event listeners
			doc.body.removeEventListener( 'keydown', this.keyDownHandler, false );
			doc.body.removeEventListener( 'click', this.bodyClickHandler, false );

			// re-enable window scrolling
			app.util.enableWindowScroll();
		}

		focusHanlder( e ) {
			this.setMenuPosition();
			this.showMenu();
		}

		blurHandler( e ) {
			
		}

		keyDownHandler( e ) {
			let index = this.currentIndex;
			let keyCode = -1;
			let menuScrollTop = this.menu.scrollTop;
			let menuHeight = this.menu.offsetHeight;
			let li;

			if ( e && e.keyCode ) {
				keyCode = e.keyCode;
				
				if ( keyCode === 9 ) {
					this.hideMenu();
					e.preventDefault();
				}

				// move down the list
				else if ( keyCode === 40 ) {
					e.preventDefault();
					this.currentIndex++;

					if ( this.currentIndex === this.listItems.length ) {
						this.currentIndex--;
						return;
					}

					if ( index >= 0 ) {
						this.listItems[index].listItem.classList.remove( cssClasses.LIST_ITEM_HOVER );
					}

					li = this.listItems[this.currentIndex].listItem;
					li.classList.add( cssClasses.LIST_ITEM_HOVER );

					// check scroll top
					if ( li.offsetTop + li.offsetHeight > menuScrollTop + menuHeight ) {
						this.menu.scrollTop = menuScrollTop + li.offsetHeight;
					}
				}

				// move up the list
				else if ( keyCode === 38 ) {
					e.preventDefault();
					this.currentIndex--;

					if ( this.currentIndex < 0 ) {
						this.currentIndex = -1;
						return;
					}

					if ( index >= 0 ) {
						this.listItems[index].listItem.classList.remove( cssClasses.LIST_ITEM_HOVER );
					}

					if ( this.currentIndex >= 0 ) {
						li = this.listItems[this.currentIndex].listItem;
						li.classList.add( cssClasses.LIST_ITEM_HOVER );

						// check scroll top
						if ( li.offsetTop < menuScrollTop ) {
							this.menu.scrollTop = menuScrollTop - li.offsetHeight;
						}
					}
				}

				// enter key is clicked
				else if ( keyCode === 13 ) {
					e.preventDefault();

					if ( this.currentIndex === -1 ) {
						this.currentIndex = 0;
					}

					// trigger list item click
					this.listItems[this.currentIndex].selectItem();
				}

				// if escape key is clicked
				else if ( keyCode === 27 ) {
					e.preventDefault();
					this.hideMenu();
				}
			}
		}

		changeHandler( e ) {
			if ( this.onChange && typeof this.onChange === 'function' ) {
				this.onChange();
			}
		}

		bodyClickHandler( e ) {
			if ( e && e.target && ( e.target === this.select || e.target.classList.contains( cssClasses.LIST_ITEM ) || e.target.classList.contains( 'btn-ripple-container' ) || e.target.classList.contains( 'btn-ripple-element' ) ) ) {
				// let the list item click event handle this
			}
			else {
				// clicked outside of the menu, hide the menu
				this.hideMenu();
			}
		}

		checkForValue() {

		}

		checkIfRequired() {
			let selected = this.menu.querySelector( '[selected="true"]' );
			let errorLbl = this.container.querySelector( '.' + cssClasses.ERROR_LABEL );
			let lbl;

			// remove any previous error messages
			if ( errorLbl ) {
				this.container.removeChild( errorLbl );
			}

			// if we don't have a selected option and this is a required field
			if ( !selected && this.select.classList.contains( cssClasses.REQUIRED_FIELD ) ) {
				lbl = doc.createElement( 'span ' );
				lbl.classList.add( cssClasses.ERROR_LABEL );
				lbl.textContent = 'Required field.';
				this.container.appendChild( lbl );
				this.select.classList.add( cssClasses.INVALID_REQ_FIELD );
			}

			// if we're all good, rmeove the invalid class
			else {
				this.select.classList.remove( cssClasses.INVALID_REQ_FIELD );
			}
		}

		setValue( value ) {
			let li = this.menu.querySelector( '[data-val="' + value + '"]' );
			let i = 0;
			let len = this.listItems.length;
			let listItem = null;

			if ( li ) {
				for ( i = 0; i < len; i++ ) {
					listItem = this.listItems[i];

					if ( listItem.listItem === li ) {
						if ( !listItem.listItem.getAttribute( 'selected' ) ) {
							listItem.selectItem();
						}
					}
				}
			}
		}

		getValue() {
			if ( !this.multiple ) {
				return this.select.options[this.select.selectedIndex].value;
			}
		}

		getTextValue() {
			if ( !this.multiple ) {
				return this.select.options[this.selectedIndex].text;
			}
		}
	}

	class ListItem {
		constructor( opt, index, select ) {
			this.listItem = doc.createElement( 'li' );
			this.select = select;
			this.index = index;

			this.listItem.classList.add( cssClasses.LIST_ITEM );
			this.listItem.classList.add( 'btn-ripple' );
			this.listItem.textContent = opt.textContent;
			this.listItem.setAttribute( 'data-val', opt.value );

			if ( opt.selected ) {
				this.listItem.setAttribute( 'selected', 'true' );
			}

			this.selectItem = this.selectItem.bind( this );

			this.addEventListeners();

			return this;
		}

		addEventListeners() {
			this.listItem.addEventListener( 'click', this.selectItem, false );
		}

		selectItem( e ) {
			let selected;
			
			// if clicking the currently selected item
			if ( this.listItem.getAttribute( 'selected' ) === 'true' ) {
				this.listItem.removeAttribute( 'selected' );

				if ( !this.select.multiple ) {
					this.select.select.value = '';
				}

				this.select.checkIfRequired();
			}
			else {
				// check if an item is already selected
				selected = this.select.menu.querySelector( '[selected="true"]' );

				// unselect a previously selected item if this is not a multiple select
				if ( selected && !this.select.multiple ) {
					selected.removeAttribute( 'selected' );
				}

				// select the selected item
				this.listItem.setAttribute( 'selected', 'true' );

				// update the selected element
				if ( !this.select.multiple ) {
					this.select.select.value = this.listItem.getAttribute( 'data-val' );
					this.select.checkIfRequired();
				}

				this.select.select.classList.remove( cssClasses.INVALID_REQ_FIELD );

				// hide the menu if this ins not a multiple select
				if ( !this.select.multiple ) {
					this.select.hideMenu();
				}
			}

			this.select.changeHandler();

			if ( e ) {
				e.stopPropagation();
			}
		}
	}

	app.Select = Select;

}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2016 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 * 
 * Corresponding HTML should follow:
 * 
 * <div class="input-field">
 *   <input type="text" id="tb-mytb" name="dbCol" />
 *   <label for="tb-mytb">Label Text</label>
 * </div>
 * 
 * To make a field required, add class="req"
 * To make a field use a datepicker, add class="input-date"
 * 
 * To autovalidate email, set type="email"
 * To auotvalidate phone numbers, set type="tel" 
 */
( function( doc ) {
	'use strict';

	const cssClasses = {
		ACTIVE_FIELD_CLASS: 'active',
		DATE_SELECTOR: 'input-date',
		ERROR_LABEL: 'error-label',
		INVALID_REQ_FIELD: 'invalid',
		REQUIRED_FIELD: 'req'
	};

	// Regex's for validating field data
	const regExpressions = {
		EMAIL: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
		TEL: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
		DATE: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
	};

	class TextBox {
		// Provide the input element for this TextBox
		constructor( input ) {
			// elements
			this.input = input;
			this.container = input.parentNode;
			this.label = this.container.querySelector( 'label' );

			// check for date
			if ( this.input.classList.contains( cssClasses.DATE_SELECTOR ) ) {
				this.initDatePicker();
			}

			// check for initial value
			this.checkForValue();

			this.initDatePicker = this.initDatePicker.bind( this );
			this.focusHandler = this.focusHandler.bind( this );
			this.blurHandler = this.blurHandler.bind( this );
			this.validateField = this.validateField.bind( this );
			this.checkForValue = this.checkForValue.bind( this );
			this.setValue = this.setValue.bind( this );
			this.getValue = this.getValue.bind( this );

			this.addEventListeners();
		}

		// Add the event listeners
		addEventListeners() {
			this.input.addEventListener( 'focus', this.focusHandler, false );
			this.input.addEventListener( 'blur', this.blurHandler, false );
			this.input.addEventListener( 'change', this.checkForValue, false );
		}

		// Initialize the date picker if the input has class .input-date
		initDatePicker() {
			let field = this.input;
			let picker;

			// make sure it is a text input
			field.type = 'text';

			// if touch device use default date picker
			if ( 'ontouchstart' in doc.documentElement ) {
				field.type = 'date';
				return;
			}

			picker = new Pikaday( {
				field: field,
				format: 'MM/DD/YYYY',
				onSelect: function() {
					field.value = this.getMoment().format( 'MM/DD/YYYY' );
				}
			} );
		}

		// OnFocus event handler
		focusHandler() {
			this.label.classList.add( cssClasses.ACTIVE_FIELD_CLASS );
		}

		// OnBlur event handler
		blurHandler() {
			this.checkForValue();
			this.validateField();
		}

		// Helper function to check for a value and handle the active class of the label
		checkForValue() {
			if ( this.getValue() !== '' ) {
				this.label.classList.add( cssClasses.ACTIVE_FIELD_CLASS );
				this.validateField();
			}
			else {
				this.label.classList.remove( cssClasses.ACTIVE_FIELD_CLASS );
			}
		}

		// Helper function to provide validation of field data based on input type and classes
		validateField() {
			let field = this.input;
			let type = field.type.toLowerCase();
			let valid = true;
			let val = this.getValue();
			let minChars = field.getAttribute( 'min-chars' );
			let prevError = this.container.querySelector( '.' + cssClasses.ERROR_LABEL );
			let lbl;
			let msg = '';

			// check for a value in a required field
			if ( field.classList.contains( cssClasses.REQUIRED_FIELD ) && val === '' ) {
				valid = false;
				msg = 'Required field.';
			}

			// check for min characters
			else if ( minChars && val.length < parseInt( minChars, 10 ) ) {
				valid = false;
				msg = minChars + ' characters required.';
			}

			// check for valid email address
			else if ( type === 'email' && !regExpressions.EMAIL.test( val ) ) {
				valid = false;
				msg = 'Invalid email address.';
			}

			// check for valid phone number
			else if ( type === 'tel' && !regExpressions.TEL.test( val ) ) {
				valid = false;
				msg = 'Inavlid phone number.';
			}

			// check for date
			else if ( field.classList.contains( cssClasses.DATE_SELECTOR ) && !regExpressions.DATE.test( val ) ) {
				valid = false;
				msg = 'Incorrect date format.';
			}

			// remove any previous error messages
			if ( prevError ) {
				this.container.removeChild( prevError );
			}

			// if the field is valid
			if ( valid ) {
				field.classList.remove( cssClasses.INVALID_REQ_FIELD );
			}

			// if invalid, make sure it is highlighted
			else {
				field.classList.add( cssClasses.INVALID_REQ_FIELD );

				// if we have a message
				if ( msg !== '' ) {
					lbl = doc.createElement( 'span' );
					lbl.classList.add( cssClasses.ERROR_LABEL );
					lbl.textContent = msg;
					this.container.appendChild( lbl );
				}
			}
		}

		// Helper function to set the value of the input
		setValue( val ) {
			this.input.value = val;
			this.checkForValue();
			this.validateField();
		}

		// Helper function get the value of the input
		getValue() {
			return this.input.value.trim();
		}
	}

	// Expose the TextBox object to the app
	app.TextBox = TextBox;

}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2017 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	var formEl = app.$( '#form-footer' ),
		form,
		btn = app.$( '#btn-contact-submit' ),
		status = app.$( '#footer-status' ),
		processing = false,
		data = {};

	if ( formEl ) {
		form = new app.Form( formEl );

		btn.addEventListener( 'click', function() {
			if ( processing ) {
				return;
			}

			processing = true;

			if ( !form.validateFields() ) {
				status.textContent = 'All fields are required.';
				processing = false;
				formEl.querySelector( '.invalid' ).focus();
				return;
			}

			btn.textContent = 'Submitting...';
			status.textContent = '';

			data = form.collectData();

			app.$.fetch( '/api/contactThroughFooter', {
				body: data
			} ).then( function( rsp ) {
				if ( rsp.success ) {
					status.textContent = 'Your information has been received.';
				}
				else {
					status.textContent = 'Unable to process your information. Please try again.';
					console.log( 'Error: ', rsp.msg || 'No error data.' );
				}

				btn.textContent = 'Submit';
				processing = false;
			} ).catch( function( rsp ) {
				status.textContent = 'Unable to process your information. Please try again.';
				console.log( 'Error: ', rsp || 'No error data.' );
				btn.textContent = 'Submit';
				processing = false;
			} );
		} );
	}

}( document ) );
/**
* Copyright 2016 Select Interactive, LLC. All rights reserved.
* @author: The Select Interactive dev team (www.select-interactive.com)
*/
(function( doc ) {
    'use strict';

    const nav = app.$( '#nav-main' );
    const btnTrigger = app.$( '#btn-nav-trigger' );
    const btnClose = app.$( '#btn-nav-close' );

    if ( nav && btnTrigger && btnClose ) {
        btnTrigger.addEventListener( 'click', toggleNav );
        btnClose.addEventListener( 'click', toggleNav );
    }

    function toggleNav() {
        doc.body.classList.toggle( 'nav-in' );
    }

}( document ) );
///<reference path="../main.js">
/**
 * Copyright 2015 Select Interactive, LLC. All rights reserved.
 * @author: The Select Interactive dev team (www.select-interactive.com) 
 */
( function( doc ) {
	'use strict';

	if ( 'serviceWorker' in navigator && doc.URL.indexOf( 'localhost' ) === -1 ) {
		navigator.serviceWorker.register( '/serviceworker.js' ).then( registration => {
			console.log( 'serviceworker registration successful with scope: ' + registration.scope );
		} ).catch( err => {
			console.log( 'serviceworker registration failed: ', err );
		} );
	}

}( document ) );